import { MOBILMEDIA, PHONEMEDIA, BSMEDIA } from './helper/constants';
import { $, $$ } from './helper/utils';
// import slider from './components/slider.js'


export default function () {
    let mobileNavLoaded = false;
    let desktopNavLoaded = false;
    const sliderLoaded = false;

    // slider();


    const loadMobileNav = () => {
        import('./components/mobileNav.js')
            .then((mobileNav) => {
                mobileNavLoaded = true;
                return mobileNav.init();
            })
            .catch((err) => {
                console.warn(`Chunk mobileNav.js loading failed: ${err}`);
            });
    };
    const loadDesktopNav = () => {
        import('./components/navigation.js')
            .then((navigation) => {
                desktopNavLoaded = true;
                return navigation.default();
            })
            .catch((err) => {
                console.warn(`Chunk navigation.js loading failed: ${err}`);
            });
    };

    if (BSMEDIA.matches) {
        loadMobileNav();
    } else {
        loadDesktopNav();
    }

    BSMEDIA.addListener((e) => {
        if (e.matches) {
            loadMobileNav();
        } else {
            loadDesktopNav();
        }
    });


    if ($('.home') !== null) {
        import('./pages/home.js')
            .then(home => home.init())
            .catch((err) => {
                console.warn(`Chunk home.js loading failed: ${err}`);
            });
    }

    if ($('main form') !== null) {
        import('./components/form.js')
            .then(form => form.init())
            .catch((err) => {
                console.warn(`Chunk form.js loading failed: ${err}`);
            });
    }

    // if ($('.filter-element') !== null) {
    //     import('./components/filter.js')
    //         .then(form => form.init())
    //         .catch((err) => {
    //             console.warn(`Chunk filter.js loading failed: ${err}`);
    //         });
    // }

    if ($('.page-catalogue') !== null) {
        import('./components/catalogue.js')
            .then(form => form.init())
            .catch((err) => {
                console.warn(`Chunk catalogue.js loading failed: ${err}`);
            });
    }
    if ($('.employee-row') !== null) {
        import('./components/tabs.js')
            .then((form) => {
                if ('bootstrapNative' in window === false) {
                    import('../libs/bs-4-native')
                        .then((bsn) => {
                            window.bootstrapNative = bsn;
                            form.init();
                        });
                } else {
                    form.init();
                }
            })
            .catch((err) => {
                console.warn(`Chunk tabs.js loading failed: ${err}`);
            });
    }
    if ($('.slideshow-grid') !== null) {
        import('./components/slider.js')
            .then(slider => slider.default())
            .catch((err) => {
                console.warn(`Chunk slider.js loading failed: ${err}`);
            });
    }
    if ($('.page-home') !== null) {
        import('./components/test.js')
            .then(test => test.default())
            .catch((err) => {
                console.warn(`Chunk test.js loading failed: ${err}`);
            });
    }
    // if ($('.accordion') !== null) {
    //     import('./components/accordion.js')
    //         .then(vehicles => vehicles.init())
    //         .catch((err) => {
    //             console.warn(`Chunk accordion.js loading failed: ${err}`);
    //         });
    // }

    if ($('.page-projects') !== null) {
        import('./pages/projects.js')
            .then(projects => projects.default('.filter-search'))
            .catch((err) => {
                console.warn(`Chunk projects.js loading failed: ${err}`);
            });
    }

    if ($('.lightbox') !== null) {
        import('./components/lightbox.js')
            .then(Lightbox => Lightbox.default('.lightbox'))
            .catch((err) => {
                console.warn('Chunk lightbox.js loading failed', err);
            });
    }

    // if ($('.slideshow-grid') !== null) {
    //     import('./components/slideshow.js').then(vehicles => {
    //         return vehicles.init();
    //     }).catch((err) => {
    //         console.warn(`Chunk vehicles.js loading failed: ${err}`);
    //     })
    // }
    if ($('.nav-tabs') !== null || $('.accordion') !== null) {
        const fadeTab = (e) => {
            const href = e.relatedTarget.getAttribute('href');
            TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
        };

        import('../libs/bs-4-native')
            .then((bsn) => {
                if ('bootstrapNative' in window === false) {
                    window.bootstrapNative = bsn;
                }
                const tabs = $$('.nav-tabs .tab-link');
                for (let i = 0; i < tabs.length; i++) {
                    const el = tabs[i];

                    // fade in new tab content
                    el.addEventListener('hide.bs.tab', fadeTab, false);
                }
            })
            .catch((err) => {
                console.warn('Chunk bs4-native.js loading failed', err);
            });
    }
}
